import(/* webpackMode: "eager" */ "/vercel/path0/apps/main-app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/main-app/src/app/provider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageview"] */ "/vercel/path0/apps/main-app/src/app/provider/Posthog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["makeApolloClient"] */ "/vercel/path0/apps/main-app/src/lib/apolloWrapper.ts");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/packages/ui/src/components/Toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/globals.css");
